@charset "utf-8";
@import 'animations.scss';
// @import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,200;0,300;0,400;0,500;1,100;1,200;1,300;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;1,200;1,300&display=swap');

*:not(i){
  font-family: 'Poppins' !important;  
}

$blue: #5fa0af;
$blueDark: #316e94;
$darkGreen: #20555B; // 71BEC3
$backgroundColor: #F6F8F9;
$grey-light: rgb(172, 172, 172);
$doneTask: #3DF2BF;
$primaryTextColor: #051B3F;
// $white-ter: #38406a;
// Update Bulma's global variables
$primary: $darkGreen;
$link: $blue;
$info: $blueDark;
$widescreen-enabled: true;
$fullhd-enabled: true;
$dark: #000521;

body, html {
  background-color: $backgroundColor !important;  
}

// Bulma tooltips
$tooltip-radius: 8px;
$tooltip-background-opacity: 1;
$tooltip-background-color: $blueDark;
$animation-transition-timing-function: ease;

[data-tooltip]:not(.is-loading)::before, [data-tooltip]:not(.is-disabled)::before, [data-tooltip]:not([disabled])::before{
  transition: all 0.3s ease;
}

.blue-gradient {
  background: linear-gradient(to right, rgb(41, 128, 185), rgb(109, 213, 250), rgb(255, 255, 255));
}

.modal-background {
  background-color: rgba(0, 0, 0, 0.705) !important;
}
div, p .title{ 
  color: #363636 !important;
}

h1, h2, h3, h4, h5, h6 {
  color: $primary;
}

hr.navbar-divider {
  background-color: transparentize($color: $primary, $amount: 0.8)
}

.title {
  &.is-1 {
    &.is-large {
      font-size: 90px !important;
    }
  }
}

label {
  color: $primary !important;
}

.is-transparent{
  background: transparent !important;
}

.is-truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.is-underlined {
  text-decoration: underline !important;
}
.notification {
  border-radius: 8px !important;
}

.has-link-shadow{
  box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.4) !important;
}

.panel-shadow {
  box-shadow: 0px 1px 20px 0px #c1c1c14d;
}
.panel-menu {
  @media only screen and (max-width: 800px) {
    display: flex;
    flex-direction: column;
  }
}
.z-index-1{
  z-index: 1 !important;
}
.navbar-burger {
  background-color: $primary;

  &:active, &:focus, &:hover {
    background-color: $primary !important;
  }
}

a.navbar-item{
  transition: all 0.5s ease;
}
a.navbar-item:focus:not(.no-hoverable), 
a.navbar-item:focus-within:not(.no-hoverable), 
a.navbar-item:hover:not(.no-hoverable), 
a.navbar-item.is-active:not(.no-hoverable), 
.navbar-link:focus:not(.no-hoverable), .navbar-link:focus-within:not(.no-hoverable), .navbar-link:hover:not(.no-hoverable), .navbar-link.is-active:not(.no-hoverable){
  color: $primary;
  background: transparentize($color: $primary, $amount: 0.8) !important;
}
.picker{
  position: absolute !important;
}
@import 'bulma';
@import '~bulma-steps';
@import '~bulma-checkradio';
@import '~@creativebulma/bulma-tooltip';

a.navbar-item:not(.no-hoverable), a.navbar-link:not(.no-hoverable) {
  height: fit-content !important;
  margin: auto 8px !important;
  border-radius: 3px !important;
}

.select:not(.is-multiple):not(.is-loading)::after, .navbar-link:not(.is-arrowless)::after{
  border-width: 2px !important;
}

.as-cursor {
  cursor: pointer;
}

.main {
  padding-left: 250px;
  width: 100%;
}

.main-card {
  width: 70%;
  background-color: #fff;
  border-radius: 40px;
  transform: translateX(-40px);
}

.overflow-auto{
  overflow: auto;
}

.image-card{
  border-radius: 8px;
}

.logo {
  width: 110px;
}

/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: white;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $primary;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: transparentize($color: $primary, $amount: 0.4);
}

.container.is-full{
  height: 100%;
  padding: 16px;
}

.steps .step-item .step-marker{
  background-color: #fff;
  border-color: #fafafa;
}
.steps .step-item.is-primary.is-active .step-marker {
  background-color: #fff;
  border-color: $primary;
}

.steps .step-item.is-primary::before {
  transition: all 0.3s ease-in;
  background: $primary;
}

.root {
  height: 100vh;
  background-color: $backgroundColor !important;  

  &.dark-theme {
    filter: invert(1) hue-rotate(180deg);

    img, picture, video {
      filter: invert(1) hue-rotate(180deg)
    }
  }
}

.app {
  display: flex;
  padding-top: 52px;

  .content {
    width: 100% !important;
    padding: 16px;
  }
}
.is-centered{
  margin: 0 auto;
}
.columns.is-vcentered {
  -webkit-box-align: center;
     -ms-flex-align: center;
        align-items: center;
}
.column {
  &.has-space-between {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  }
}

li{
  list-style: none;
}

a.pagination-link, .pagination-previous, .pagination-next {
  box-shadow: none;
  border: none;
  color:white;
  transition: all 0.2s ease;

  &:hover {
    background-color: #171e40;
    color: white;
  }

  &.is-current {
    border: none;
    background-color: $link;
  }
}
.is-fullwidth {
  width: 100% !important;
}
.is-sticky {
  position: sticky;
  top: 0px;
}

.shadow-sticky {
  position: sticky;
  top: 72px;
  box-shadow: rgba(220, 220, 220, 0.27) 0px 3px 6px 8px;
}
.select:not(.is-multiple):not(.is-loading)::after, .navbar-link:not(.is-arrowless)::after {
  border-width: 1px !important;
}

.navbar-link:not(.is-arrowless)::after{
  border-color: $primary !important;
}

a:not(.is-underlined) {
  text-decoration: none !important;
}

.login {
  .notification:not(.normal){
    max-width: 500px;
    margin: 0 auto;
    box-shadow: rgba(220, 220, 220, 0.27) 0px 3px 6px 8px;
  }
}

@media only screen and (max-width: 700px) {
  .login {
    padding: 0px !important;

    .notification:not(.normal){
      height: 100vh;
      max-width: 100%;
      margin: 0 auto;
      box-shadow: rgba(220, 220, 220, 0.27) 0px 3px 6px 8px;
    }
  }
  .button.is-fullwidth-mobile {
    display: flex;
    width: 100%;
  }
  .is-full-mobile{
    width: 100% !important;
  }
}

@media all and (display-mode: fullscreen) {
  .hide-fullscreen {
    display: none !important;
  }
  .app {
    padding-top: 0px;
  }
  .main {
    padding-left: 0px;
  }
}
// TABS
.tabs {
  ul {
    li {
      padding: 7px;
      background: rgb(233, 233, 233);

      &:first-child {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }

      &:last-child {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }

      a {
        border-radius: 4px !important;
        &:hover{
          background-color: transparentize($color: $primary, $amount: 0.8) !important;
          color: $primary !important;

          &.is-active {
            background-color: $primary !important;
            color: $white !important;
          }
        }

        &.is-active {
          background-color: $primary;
          color: white !important;
        }
        border:none !important;
      }
    }
  }
}
// TABLE
.table {
  background: transparent;
  border-radius: 8px;

  /*&.is-striped {
    tbody {
      tr:not(.is-selected){
        &:hover {
          background: #000521;
        }
      }
    }
  }*/

  &.is-striped {
    tbody {
      tr:not(.is-selected):nth-child(even) {
        background: #171e44;
      }
      
      tr {
        &.is-selected {
          background: #65e4df1a;
          box-shadow: inset 3px 0px 0px 0px #64e4df;
        }
      }

      td {
        border:none;
      }
    }
  }

  thead {
    th {
      height: 50px;
      line-height: 2;
    }

    th:first-child {
      border-top-left-radius: 8px;      
    }

    th:last-child {
      border-top-right-radius: 8px;      
    }
  }

  tbody {
    tr {
      color: #ffffffa1;
    }
  }
}

.info-form {
  input {
    text-transform: uppercase;
  }
}

.text {
  &.yellow { color: yellow }
  &.green { color: green }
  &.red { color: red }
} 

.show-on-print {
  display: none;
}

.no-show-on-print {
  display: block;
}
@page {
  size: letter;
  margin: 0px;
}
@media print {  
  .show-on-print {  display: block !important; }
  .no-show-on-print {  display: none !important; }

  .has-background-light {
   background-color: whitesmoke !important;
    -webkit-print-color-adjust: exact; 
  }
}


@media only screen and (max-width: 800px) {
  .p-6 {
    padding: 1rem !important;
  }
  .px-6 {
    padding-left:  1rem !important;
    padding-right:  1rem !important;
  }
}